@font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: bold;
    src: url("../fonts/proxima-nova-bold-webfont.woff"),
        url("../fonts/proxima-nova-bold-webfont.woff2");
}
@font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/proxima-nova-regular-webfont.woff"),
        url("../fonts/proxima-nova-regular-webfont.woff2");
}
@font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/proxima-nova-semibold-webfont.woff"),
        url("../fonts/proxima-nova-semibold-webfont.woff2");
}

html {
    margin-right: calc(100% - 100vw);
    overflow-x: hidden;
}
body {
    font-family: "Proxima Nova", "Helvetica", "sans-serif";
    background-color: #f5f5f6;
}
div[tabindex="-1"]:focus {
    outline: 0;
}

.react-datepicker__input-container {
    display: block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid white;
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
}

a {
    text-decoration: none;
}

.background_strong {
    background-image: url("../Busy_Box_Strong.svg");
}

.background_light {
    background-image: url("../Busy_Box_Light.svg");
}

.background_monday {
    background-image: url("../Busy_Box_Monday.svg");
}

/* ANIMATIONS */

/* notificator on mount */
.notificator-enter {
    opacity: 0.8;
    transform: translateY(40px);
}

.notificator-enter.notificator-enter-active {
    opacity: 1;
    transition: all 300ms ease-in-out;
    transform: translateY(0);
    will-change: transform;
}

.notificator-leave {
    opacity: 1;
    transform: translateY(0);
}

.notificator-exit.notificator-exit-active {
    opacity: 0.8;
    transition: transform 300ms ease-in-out;
    transform: translateY(40px);
    will-change: transform;
}

/* Hard blinking effect */
@keyframes blinker {
    0%,
    50% {
        opacity: 0;
    }

    51%,
    100% {
        opacity: 1;
    }
}

/* Airbnb datepicker */
.CalendarDay,
.CalendarDay:active,
.CalendarDay:hover {
    outline: unset;
    background: #fff;
    border: solid 1px #e5e5e5;
    color: #182d3e;
}

.CalendarDay:hover {
    background: #eaecee;
}

.CalendarDay__highlighted_calendar,
.CalendarDay__highlighted_calendar:active {
    background: #ecf3ff;
    border: 1px double #e2ecfe;
}

.CalendarDay__highlighted_calendar:hover {
    background: #b9d3ff;
    border: 1px double #b1ccfc;
}

.CalendarDay__firstDayOfWeek.CalendarDay__highlighted_calendar,
.CalendarDay__firstDayOfWeek.CalendarDay__highlighted_calendar:active {
    background: #4b78fa;
    border: 1px double #4b78fa;
    color: #fff;
}

.CalendarDay__firstDayOfWeek.CalendarDay__highlighted_calendar:hover {
    background: #5c85fa;
    border: 1px double #5c85fa;
    color: #fff;
}

.CalendarDay__lastDayOfWeek.CalendarDay__highlighted_calendar,
.CalendarDay__lastDayOfWeek.CalendarDay__highlighted_calendar:active {
    background: #4b78fa;
    border: 1px double #4b78fa;
    color: #fff;
}

.CalendarDay__lastDayOfWeek.CalendarDay__highlighted_calendar:hover {
    background: #5c85fa;
    border: 1px double #5c85fa;
    color: #fff;
}

.SingleDatePickerInput .DateInput .DateInput_input {
    display: none;
}

.DateInput_fang {
    margin-top: -31px;
    left: -29px;
    z-index: 51;
}

.DateInput_fangShape {
    fill: #f5f5f6;
}

.SingleDatePickerInput {
    width: 38px;
    height: 38px;
    background: unset;
    border-radius: 6px;
    border: 1px solid #e0e2e5;
}

.SingleDatePickerInput:hover {
    border: 1px solid #d5d9dd;
}

.SingleDatePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    outline: unset;
}

.SingleDatePickerInput_calendarIcon:hover {
    color: #4b78fa;
}

.SingleDatePicker_picker {
    margin-left: -138px;
    margin-top: -13px;
    z-index: 50;
    background-color: unset;
}

.DayPickerNavigation {
    display: flex;
    left: 22px;
    top: 15px;
    width: 100%;
    justify-content: space-between;
}

.DayPickerNavigation::before {
    content: "";
    position: absolute;
    width: 75px;
    height: 27px;
    background-image: linear-gradient(
        to left,
        rgba(1, 1, 1, 0) -16px,
        #f5f5f6 28px
    );
    left: -22px;
    z-index: -1;
}
.DayPickerNavigation::after {
    content: "";
    position: absolute;
    width: 75px;
    height: 27px;
    background-image: linear-gradient(
        to right,
        rgba(1, 1, 1, 0) -16px,
        #f5f5f6 28px
    );
    right: 21px;
    z-index: -1;
}

.DayPickerNavigation_button {
    outline: unset;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    padding: 0;
    margin-right: 44px;
    color: #182d3e;
    border: solid 1px #e0e2e5;
    background: #f5f5f6;
    font-size: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.DayPickerNavigation_button:hover,
.DayPickerNavigation_button:active {
    color: #4b78fa;
    border: solid 1px #d5d9dd;
}

.DayPicker {
    border-radius: 6px;
    background-image: linear-gradient(to bottom, #f5f5f6 55px, #fff 1px);
    border: solid 1px #e0e2e5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.CalendarMonth.CalendarMonth_1 {
    padding: 0;
    background: unset;
}
.CalendarMonth_1 {
    padding: 0;
}
.CalendarMonthGrid {
    background: unset;
}
.CalendarMonth_caption {
    background: #f5f5f6;
    padding: 17px 0;
    border-bottom: solid 1px #e0e2e5;
    margin: 0 -22px;
    border-radius: 6px 6px 0 0;
    color: #182d3e;
    font-size: 16px;
    width: calc(100% + 45px);
}

.DateInput_fangStroke {
    stroke: #e0e2e5;
}

.CalendarMonth_table {
    margin-top: 30px;
}

.DayPicker_weekHeader {
    color: #abb1b9;
}

.DayPicker_transitionContainer {
    width: 319px !important;
    padding-bottom: 24px;
    transition: none;
}

.DateRangePicker_picker .DayPicker_transitionContainer {
    width: 619px !important;
}

.DateRangePickerInput {
    background: unset;
}

.DateRangePickerInput .DateInput_fang {
    display: none;
}

.DateInput {
    width: unset;
    background: unset;
}

.DateInput_input {
    background: unset;
    width: 100px;
    padding: 0;
    height: 38px;
    border: 1px solid #e0e2e5;
    font-size: 12px;
    color: #182d3e;
    text-align: center;
    font-weight: 700;
    font-family: inherit;
    cursor: pointer;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
}

.DateInput_input:hover,
.DateInput_input:active {
    color: #4b78fa;
    border: 1px solid #d5d9dd;
}

.DateInput_input__focused {
    color: #4b78fa;
    border: 1px solid #d5d9dd;
}

.DateRangePicker_picker {
    left: -393px !important;
    top: 42px !important;
    background-color: unset;
}

.DateRangePickerInput_arrow_svg {
    height: 14px;
    padding: 12px 0;
    border-top: 1px solid #e0e2e5;
    border-bottom: 1px solid #e0e2e5;
    background: #e0e2e5;
    fill: #abb1b9;
}

#rangePickerStartDateInput {
    border-radius: 6px 0 0 6px;
}

#rangePickerEndDateInput {
    border-radius: 0 6px 6px 0;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:active {
    background: #ecf3ff;
    border: 1px double #e2ecfe;
    color: #182d3e;
}

.CalendarDay__selected_span:hover {
    background: #b9d3ff;
    border: 1px double #b1ccfc;
    color: #182d3e;
}

.CalendarDay__selected,
.CalendarDay__selected:active {
    background: #4b78fa;
    color: #fff;
}

.CalendarDay__selected:hover {
    background: #5c85fa;
    color: #fff;
}
.SingleDatePickerInput__block {
    display: none;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #f9f9fa !important;
    color: #abb1b9 !important;
}
